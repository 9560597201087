@font-face {
  font-family: 'TmoneyRoundWind';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-07@1.0/TmoneyRoundWindRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

:root {
  --font: 'TmoneyRoundWind';
  --background-color: #fff;
  --primary-text-color: #002663;
  --secondary-text-color: #52cbff;
  --accent-text-color: #f5a3cf;
  --border-color: hsla(0, 0%, 0%, 0.2);
}

.Color-alt {
  --background-color: #002663;
  --primary-text-color: #fff;
  --secondary-text-color: #fff;
  --accent-text-color: #fff;
  --border-color: hsla(0, 0%, 100%, 0.2);
}

.App {
  text-align: center;
  background-color: var(--background-color);
  transition: background-color 0.5s ease-in-out 0s, color 0.5s ease-in-out 0s, border-color 0.5s ease-in-out 0s, background-image 0.5s ease-in-out 0s;
}

.Player-container {
  width: 90%;
  max-height: 40vh;
  position: relative;
  padding-bottom: 50.625%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 16px;
}

iframe.Player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a:link {
  color: #002E5D;
  text-decoration: none;
}

a:visited {
  color: #002E5D;
  text-decoration: none;
}

a:hover {
  color: rgb(20, 116, 137);
  text-decoration: none;
}

a:active {
  color: rgb(20, 116, 137);
  text-decoration: none;
}