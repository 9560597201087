.Section-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Title-container {
    justify-content: 'space-between';
    height: calc(100vh - 48px);
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Main-banner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 85%;
    flex-grow: 1;
}

.Title {
    color: var(--primary-text-color);
    text-align: start;
    font-family: var(--font);
    font-size: 80px;
    font-weight: 400;
    word-break: keep-all;
}

.Text-bold {
    /* font-weight: 600; */
    font-weight: 400;
    -webkit-text-stroke: 0.03em currentColor;
    color: var(--secondary-text-color);
}

.Text-bold-accent {
    /* font-weight: 600; */
    font-weight: 400;
    -webkit-text-stroke: 0.03em currentColor;
    color: var(--accent-text-color);
}

.More-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 66px;
    animation: 0.4s cubic-bezier(0.7, -0.25, 0.3, 1) 0s 1 normal both running fadeOut;
}

.More-bar-text {
    color: var(--primary-text-color);
    font-family: var(--font);
    font-size: 16px;
    /* font-weight: 600; */
    font-weight: 400;
    -webkit-text-stroke: 0.03em currentColor;
    animation: 2s cubic-bezier(0.6, 0, 0.4, 1) 0s infinite normal none running bounce;
}

.More-bar-fade-in {
    animation: 0.7s cubic-bezier(0.7, -0.25, 0.3, 1) 0s 1 normal both running fadeIn;
}

/** Generic container for all rolling pages. */
.Content-container {
    padding: 0px 40px;
    min-height: 1250px;
}

.Header {
    color: var(--primary-text-color);
    text-align: start;
    font-family: var(--font);
    font-size: 56px;
    font-weight: 400;
    word-break: keep-all;
    position: sticky;
    top: calc(1vh*20);
    margin-top: 0px;
    margin-bottom: 40px;
}

/** Container with margins for pages with detailed descriptions. */
.Description-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    color: var(--primary-text-color);
    /* text-align: start; */
    font-family: var(--font);
    word-break: keep-all;
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0px 64px;
}

.Description-title {
    font-size: 48px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 64px;
}

.Description-line {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 24px;
    font-weight: 400;
    text-align: start;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid var(--border-color);
}

.Description-text-xsmall {
    font-size: 20px;
    font-weight: 400;
    text-align: start;
    margin-bottom: 32px;
    width: 100%;
}

.Description-half-container {
    display: flex;
    flex-direction: row;
}

.Description-text-small {
    font-size: 24px;
    font-weight: 400;
    text-align: start;
    margin-bottom: 32px;
}

.Section-image {
    max-height: 240px;
    width: auto;
    height: auto;
    border-radius: 8px;
    border: 2px solid var(--border-color);
    margin-bottom: 24px;
    margin-right: 48px;
}

.Player-container {
    width: 100%;
    position: relative;
    padding-bottom: 50.625%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 16px;
    border-radius: 8px;
}

.Frame-container {
    margin-top: 64px;
    position: relative;
    width: 100%;
    min-height: 400px;
    border-radius: 8px;
}

.Frame-container-sticky {
    margin-top: 24px;
    position: relative;
    width: 100%;
    min-height: 400px;
    border-radius: 8px;
    position: sticky;
    top: calc(1vh*25);
}

iframe.Frame {
    border-radius: 8px;
    border: 2px solid var(--border-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.Link-container {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
}

.Link-phone {
    padding: 12px 24px;
    background-color: #0EBE2C;
    font-size: 16px;
    /* font-weight: 700; */
    font-weight: 400;
    color: white;
    -webkit-text-stroke: 0.03em currentColor;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Link-phone:link {
    color: #fafafa;
    text-decoration: none;
}

.Link-phone:visited {
    color: #fafafa;
    text-decoration: none;
}

.Link-phone:hover {
    color: #8aff9e;
    text-decoration: none;
}

.Link-phone:active {
    color: #8aff9e;
    text-decoration: none;
}

.Link-kakao {
    padding: 12px 24px;
    background-color: #ffe812;
    font-size: 16px;
    /* font-weight: 700; */
    font-weight: 400;
    color: var(--primary-text-color);
    -webkit-text-stroke: 0.03em currentColor;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
}

.Link-kakao:link {
    color: #3F3035;
    text-decoration: none;
}

.Link-kakao:visited {
    color: #3F3035;
    text-decoration: none;
}

.Link-kakao:hover {
    color: #938563;
    text-decoration: none;
}

.Link-kakao:active {
    color: #938563;
    text-decoration: none;
}

/** Large desktop */
@media (min-width : 1024px) {
    .Content-container {
        width: 80%;
        align-self: center;
    }

    .Content {
        font-size: 72px;
    }

    .Description-title {
        font-size: 56px;
    }

    .Description-text {
        font-size: 36px;
    }

    .More-bar-text {
        font-size: 24px;
    }

    .Title {
        font-size: 96px;
    }

    .Title-container {
        padding: 0px 72px;
    }

    .Description-line {
        font-size: 36px;
        padding: 24px 0px;
    }

    .Frame-container-sticky {
        top: calc(1vh*15);
    }
}

/** Mobile or small screens */
@media (max-width : 780px), only screen and ((orientation: landscape) and (max-height: 780px)) {
    .Main-banner {
        width: 100%;
    }

    .More-bar {
        /** Address bar is on the bottom */
        height: 120px;
    }

    .Title {
        font-size: 42px;
    }

    .Title-container {
        padding: 0px 28px;
        height: calc(100vh - 128px);
    }

    .Content {
        font-size: 36px;
    }

    .Content-container {
        padding: 0px 28px;
        min-height: 1000px;
    }

    .Description-container {
        padding: 0px 0px;
    }

    .Description-title {
        font-size: 36px;
    }

    .Description-text-small {
        font-size: 20px;
    }

    .Header {
        font-size: 36px;
    }

    .Link-container {
        flex-direction: column;
    }

    .Link-kakao {
        margin-left: 0px;
        margin-top: 8px;
    }

    .Section-image {
        margin-right: 0px;
        margin-bottom: 36px;
        max-height: 20vh;
    }

    .Description-half-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .Frame-container-sticky {
        top: calc(1vh*25);
        min-height: 0px;
        width: 100%;
    }

    .Footer {
        padding: 32px;
    }
}

/** Mobile landscape */
@media only screen and (orientation: landscape) and (max-height: 780px) {
    .Frame-container-sticky {
        top: calc(1vh*16);
        min-height: 0px;
        width: calc(80% - 64px);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .Title-container {
        padding: 0px 28px;
        height: calc(100vh - 48px);
    }

    .Header { 
        top: calc(1vh*30);
    }

    .Player-container {
        justify-content: center;
        text-align: center;
    }

    .Frame-container {
        width: 80%;
        min-height: calc(90vh - 64px);
    }

    .Description-half-container {
        display: flex;
        flex-direction: row;
        align-items: start;
    }

    .Section-image {
        max-height: 240px;
        width: auto;
        height: auto;
        margin-bottom: 24px;
        margin-right: 48px;
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0%);
    }

    50% {
        transform: translateY(25%);
    }

    100% {
        transform: translateY(0%);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}