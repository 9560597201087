.App-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    width: 100%;
    background-color: var(--background-color);
    transition: background-color 0.5s ease-in-out 0s, color 0.5s ease-in-out 0s, border-color 0.5s ease-in-out 0s, background-image 0.5s ease-in-out 0s;
    padding: 0px 20px;
    border-bottom: 1px solid var(--border-color);
    z-index: 2;
    position: fixed;
}

.Logo-img {
    height: 30px;
    padding-right: 20px;
}

.Logo-text {
    font-family: var(--font);
    font-size: 20px;
    color: var(--primary-text-color);
    padding-top: 2px;
    /* font-weight: 600; */
    font-weight: 400;
    -webkit-text-stroke: 0.03em currentColor;
}

@media (max-width : 780px) {
    .Logo-text {
        font-size: 16px;
    }

    .Logo-img {
        height: 20px;
        padding-right: 12px;
    }
}